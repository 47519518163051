export type Toast = {
  id?: string | number;
  type?: "success" | "error" | "info";
  title?: string;
  content?: string;
  timing?: number;
  timerId?: number | NodeJS.Timeout;
};

let toastId = 1;
const toasts = ref<Toast[]>([]);

export const useToast = () => {
  const toast = (value: Toast) => {
    if (!toasts.value?.length) {
      toastId = 1;
    }

    if (toasts.value?.length == 4) {
      toasts.value.shift();
    }

    const toast: Toast = {
      id: `t-${toastId}`,
      type: "info",
      timing: 3000,
      ...value,
    };

    toast.timerId = setTimeout(() => {
      removeToast(toast);
    }, toast?.timing);

    toasts.value.push(toast);

    toastId++;
  };

  const removeToast = (toast: Toast) => {
    const currentToastIndex = toasts.value.findIndex(
      (item) => item?.id === toast?.id
    );

    toasts.value.splice(currentToastIndex, 1);

    clearTimeout(toast?.timerId);
  };

  return {toasts, toast, removeToast};
};
