import axios from "axios";

export default defineNuxtPlugin(() => {
  const runtimeVars = useRuntimeConfig();
  const token = useCookie("token");

  const axiosInstance = () => {
    const instance = axios.create({
      baseURL: runtimeVars?.public?.apiBaseUrl + "",
    });

    instance.interceptors.request.use(
      (config: any) => {
        if (token?.value) config.headers.Authorization = token?.value;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    return instance;
  };

  return {
    provide: {
      api: axiosInstance(),
    },
  };
});
