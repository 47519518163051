import { default as icons_45pageFvABfz3l5RMeta } from "/var/www/the-future-sa-website/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue?macro=true";
import { default as _91slug_93tTHXysZeZJMeta } from "/var/www/the-future-sa-website/pages/articles/[slug].vue?macro=true";
import { default as indexX503JvUvu6Meta } from "/var/www/the-future-sa-website/pages/articles/index.vue?macro=true";
import { default as careerkDkvifcDTKMeta } from "/var/www/the-future-sa-website/pages/career.vue?macro=true";
import { default as contactusV2gmw0jVu4Meta } from "/var/www/the-future-sa-website/pages/contactus.vue?macro=true";
import { default as indexWKSYkgYCD1Meta } from "/var/www/the-future-sa-website/pages/index.vue?macro=true";
import { default as _91slug_931DsyMviYGtMeta } from "/var/www/the-future-sa-website/pages/projects/[slug].vue?macro=true";
import { default as indexPJ9lYeKE8hMeta } from "/var/www/the-future-sa-website/pages/projects/index.vue?macro=true";
import { default as testoR7VVxgSFrMeta } from "/var/www/the-future-sa-website/pages/test.vue?macro=true";
export default [
  {
    name: icons_45pageFvABfz3l5RMeta?.name ?? "icons-page",
    path: icons_45pageFvABfz3l5RMeta?.path ?? "/_icons",
    meta: {...(icons_45pageFvABfz3l5RMeta || {}), ...{"layout":"svg-sprite"}},
    alias: icons_45pageFvABfz3l5RMeta?.alias || [],
    redirect: icons_45pageFvABfz3l5RMeta?.redirect || undefined,
    component: () => import("/var/www/the-future-sa-website/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93tTHXysZeZJMeta?.name ?? "articles-slug",
    path: _91slug_93tTHXysZeZJMeta?.path ?? "/articles/:slug()",
    meta: _91slug_93tTHXysZeZJMeta || {},
    alias: _91slug_93tTHXysZeZJMeta?.alias || [],
    redirect: _91slug_93tTHXysZeZJMeta?.redirect || undefined,
    component: () => import("/var/www/the-future-sa-website/pages/articles/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexX503JvUvu6Meta?.name ?? "articles",
    path: indexX503JvUvu6Meta?.path ?? "/articles",
    meta: indexX503JvUvu6Meta || {},
    alias: indexX503JvUvu6Meta?.alias || [],
    redirect: indexX503JvUvu6Meta?.redirect || undefined,
    component: () => import("/var/www/the-future-sa-website/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: careerkDkvifcDTKMeta?.name ?? "career",
    path: careerkDkvifcDTKMeta?.path ?? "/career",
    meta: careerkDkvifcDTKMeta || {},
    alias: careerkDkvifcDTKMeta?.alias || [],
    redirect: careerkDkvifcDTKMeta?.redirect || undefined,
    component: () => import("/var/www/the-future-sa-website/pages/career.vue").then(m => m.default || m)
  },
  {
    name: contactusV2gmw0jVu4Meta?.name ?? "contactus",
    path: contactusV2gmw0jVu4Meta?.path ?? "/contactus",
    meta: contactusV2gmw0jVu4Meta || {},
    alias: contactusV2gmw0jVu4Meta?.alias || [],
    redirect: contactusV2gmw0jVu4Meta?.redirect || undefined,
    component: () => import("/var/www/the-future-sa-website/pages/contactus.vue").then(m => m.default || m)
  },
  {
    name: indexWKSYkgYCD1Meta?.name ?? "index",
    path: indexWKSYkgYCD1Meta?.path ?? "/",
    meta: indexWKSYkgYCD1Meta || {},
    alias: indexWKSYkgYCD1Meta?.alias || [],
    redirect: indexWKSYkgYCD1Meta?.redirect || undefined,
    component: () => import("/var/www/the-future-sa-website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_931DsyMviYGtMeta?.name ?? "projects-slug",
    path: _91slug_931DsyMviYGtMeta?.path ?? "/projects/:slug()",
    meta: _91slug_931DsyMviYGtMeta || {},
    alias: _91slug_931DsyMviYGtMeta?.alias || [],
    redirect: _91slug_931DsyMviYGtMeta?.redirect || undefined,
    component: () => import("/var/www/the-future-sa-website/pages/projects/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexPJ9lYeKE8hMeta?.name ?? "projects",
    path: indexPJ9lYeKE8hMeta?.path ?? "/projects",
    meta: indexPJ9lYeKE8hMeta || {},
    alias: indexPJ9lYeKE8hMeta?.alias || [],
    redirect: indexPJ9lYeKE8hMeta?.redirect || undefined,
    component: () => import("/var/www/the-future-sa-website/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: testoR7VVxgSFrMeta?.name ?? "test",
    path: testoR7VVxgSFrMeta?.path ?? "/test",
    meta: testoR7VVxgSFrMeta || {},
    alias: testoR7VVxgSFrMeta?.alias || [],
    redirect: testoR7VVxgSFrMeta?.redirect || undefined,
    component: () => import("/var/www/the-future-sa-website/pages/test.vue").then(m => m.default || m)
  }
]