<template>
  <Teleport to="#app-teleport">
    <Transition name="slide">
      <div
        v-if="toasts?.length"
        class="fixed top-0 start-0 z-50 w-full md:w-auto h-auto p-2"
      >
        <transition-group name="slide" tag="div" class="flex flex-col gap-2">
          <ToastBody v-for="toast in toasts" :key="toast?.id" :toast="toast" />
        </transition-group>
      </div>
    </Transition>
  </Teleport>
</template>

<script lang="ts" setup>
const {toasts} = useToast();
</script>

<style lang="postcss" scoped>
.slide-enter-active {
  @apply transition-all duration-200 delay-300;
}

.slide-leave-active {
  @apply transition-all duration-200;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>
