<template>
  <div
    class="toast-body relative z-50 rounded-2xl border border-transparent bg-white border-gray-200 w-full md:w-auto p-4 flex items-center gap-x-3"
    :class="[toast?.type]"
  >
    <div class="w-full">
      <p>{{ toast?.content }}</p>
    </div>
    <button
      @click="removeToast(toast)"
      class="toast-close w-7 h-7 rounded-full text-white bg-red-500 flex items-center justify-center shrink-0"
    >
      <svg-icon name="close" class="fill-current w-3 h-3" />
    </button>
  </div>
</template>

<script lang="ts" setup>
type Props = {
  toast: Toast;
};

defineProps<Props>();

const {removeToast} = useToast();
</script>

<style lang="postcss" scoped>
.toast {
  &-body {
    &.success {
      @apply border-green-600;
      .toast {
        &-close {
          @apply bg-green-400;
        }
      }
    }
    &.error {
      @apply border-red-500;
      .toast {
        &-close {
          @apply bg-red-200;
        }
      }
    }

    &.info {
      @apply border-blue-500;
      .toast {
        &-close {
          @apply bg-blue-200;
        }
      }
    }
  }
}
</style>
